import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import sanityClient from '../../client'
import imageUrlBuilder from '@sanity/image-url'

const formatISK = (price) =>
  `${price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'}`

export default function Trip() {
  const [doc, setDocData] = useState(null)

  const { slug } = useParams()

  const builder = imageUrlBuilder(sanityClient)
  function urlFor(source) {
    return builder.image(source)
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug == "${slug}"]{
            title,
            slug,
            itineraries,
            hotels,
            soldout,
            coverImage,
            priceConfirmation,
            priceForUpgrade,
            price,
            included,
            excluded
          }`
      )
      .then((data) => setDocData(data[0]))
      .catch(console.error)
  }, [slug])

  console.log(doc)

  const formatDateString = (date) => {
    const yyyy = date?.split('-')[0]
    const mm = date?.split('-')[1]
    const dd = date?.split('-')[2]
    return `${dd}.${mm}.${yyyy}`
  }

  return (
    doc && (
      <>
        <section className="hero">
          <img
            src={urlFor(doc.coverImage)}
            alt=""
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              // maxHeight: width > 700 ? '700px' : '500px',
              zIndex: 0,
              objectFit: 'cover',
            }}
          />
          <div className="auto__container">
            <div className="hero__inner">
              <h2>{doc.title}</h2>
            </div>
          </div>
        </section>
        <section className="tour">
          <div className="auto__container">
            <div className="tour__inner">
              <div className="tour__inner-header">
                <h3 className="big">{doc.title}</h3>
                {/* <h5 className="gray">September 2022</h5> */}
              </div>
              <div className="tour__inner-body">
                <div className="tour__inner-body-main">
                  {doc.itineraries.map((item, index) => {
                    return (
                      <div className="tourItem" key={index}>
                        <div className="tourItem__info">
                          <div className="tourItem__info-gen">
                            <h5>
                              {item.date ? formatDateString(item.date) : ''}{' '}
                              <span>
                                {' '}
                                {item.from} {item.to && ` - ${item.to}`}
                              </span>
                            </h5>
                            {item.description}
                          </div>
                        </div>
                      </div>
                    )
                  })}

                  {doc.hotels?.length && (
                    <>
                      <br />
                      <br />
                      <br />
                      <h3>Gisting</h3>
                      <br />
                      {doc.hotels.map((item, index) => {
                        return (
                          <div className="tourItem" key={index}>
                            <div className="tourItem__info">
                              <div className="tourItem__info-gen">
                                <h5>
                                  {item.name}{' '}
                                  <span>
                                    {!!item.rating &&
                                      `${new Array(Math.floor(item.rating) || 0)
                                        .fill('★')
                                        .join('')}${item.rating % 1 !== 0 ? '☆' : ''}`}
                                  </span>
                                </h5>
                                {item.location}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )}

                  <br />
                  <br />

                  <h3 className="big">Verð</h3>
                  <div className="price__outer">
                    <div className="price">
                      <h5>Verð á mann</h5>
                      <h5 className="price__number">
                        {formatISK(doc.price)} <small>kr</small>
                      </h5>
                    </div>
                    {doc.priceConfirmation && (
                      <div className="price">
                        <h5>Staðfestingargjald</h5>
                        <h5 className="price__number">
                          {formatISK(doc.priceConfirmation)} <small>kr</small>
                        </h5>
                      </div>
                    )}
                    {doc.priceForUpgrade && (
                      <div className="price">
                        <h5>Viðbótargjald f. einbýli</h5>
                        <h5 className="price__number">
                          {formatISK(doc.priceForUpgrade)} <small>kr</small>
                        </h5>
                      </div>
                    )}
                  </div>
                  <div className="tour__inner-more">
                    {doc.included && (
                      <>
                        <h5>Innifalið</h5>
                        <p>{doc.included}</p>
                      </>
                    )}
                    {doc.excluded && (
                      <>
                        <h5>Ekki innifalið</h5>
                        <p>{doc.excluded}</p>
                      </>
                    )}
                    <h5>
                      Frekari upplýsingar og skráning þátttöku:{' '}
                      <a href="mailto:ornolfur@islandia.is.">ornolfur@islandia.is</a> /
                      {/* <a href="tel:6912330">6912330</a> */}
                    </h5>
                  </div>
                </div>

                <div className="tour__inner-body-side">
                  {doc.itineraries
                    .filter((i) => !!i.image)
                    .map((item, index) => {
                      return (
                        <a href={item.link} className="tourCard" key={index}>
                          <div className="tourCard__image">
                            {item.image && <img src={urlFor(item.image)} alt="trip" />}
                          </div>
                          <h6>{item.from}</h6>
                        </a>
                      )
                    })}
                </div>
              </div>

              <div className="tour__inner-footer"></div>
            </div>
          </div>
        </section>
      </>
    )
  )
}
