export const introModul = [
  {
    id: "1",
    image: "/images/trips/trip-1.jpg",
    title: "Balí - Október 2022",
    link: "#",
    text: "Lesa meira",
  },
  {
    id: "2",
    image: "/images/trips/trip-2.jpg",
    link: "#",
    title: "Marokkó og Andalúsíu",
    text: "Lesa meira",
  },
  {
    id: "3",
    image: "/images/trips/trip-3.jpg",
    link: "#",
    title: "Singapúr, Víetnam og Kambódía",
    text: "Lesa meira",
  },
];
export const tripModul = [
  {
    id: "1",
    image: "/images/beforeTrips/trip-1.jpg",
    title: "Litháen og Hvítarússland",
    link: "#",
  },
  {
    id: "2",
    image: "/images/beforeTrips/trip-2.jpg",
    link: "#",
    title: "Marokkó og Andalúsíu",
  },
  {
    id: "3",
    image: "/images/beforeTrips/trip-3.jpg",
    link: "#",
    title: "Singapúr, Víetnam og Kambódía",
  },
  {
    id: "4",
    image: "/images/beforeTrips/trip-4.jpg",
    link: "#",
    title: "Singapúr, Víetnam og Kambódía",
  },
];
export const tourModul = [
  {
    id: "1",
    image: "/images/tour/trip-1.jpg",
    title: "Malage",
    link: "#",
  },
  {
    id: "2",
    image: "/images/tour/trip-2.jpg",
    link: "#",
    title: "Marrakech",
  },
  {
    id: "3",
    image: "/images/tour/trip-3.jpg",
    link: "#",
    title: "Asilah",
  },
  {
    id: "4",
    image: "/images/tour/trip-4.jpg",
    link: "#",
    title: "Tangier",
  },
  {
    id: "5",
    image: "/images/tour/trip-5.jpg",
    link: "#",
    title: "Sevilla",
  },
  {
    id: "6",
    image: "/images/tour/trip-6.jpg",
    link: "#",
    title: "Granada",
  },
  {
    id: "7",
    image: "/images/tour/trip-7.jpg",
    link: "#",
    title: "Malaga",
  },
];
export const aboutModul = [
  {
    id: "1",
    date: "13. september 2022",
    title: "Keflavík - Malaga.",
    text: (
      <>
        <p>
          Flogið með SK 596 kl.19:35 frá Keflavík til Kaupmannahafnar, lent á
          Kastrup 15:35 og síðan haldið áfram kl. 16:40 með SK583 til Málaga og
          lent kl. 20:30. Ekið til Algeciras, rétt við Gíbraltar, og gist þar á
          Hotel Reina Cristina.
        </p>
      </>
    ),
  },
  {
    id: "2",
    date: "12. september 2022",
    title: "Malaga.",
    text: (
      <>
        <p>
          Eftir morgunverð er ekið til Tarifa (23 km) og siglt kl. 10.00 með
          hraðferju yfir Gíbraltarsund til Tangier. Siglingin tekur innan við
          klukkustund, Síðan ekið til Fes (4 1⁄2 klst) með viðkomu í hafnarbænum
          Larache þar sem snæddur er hádegisverður. Kvöldverður á Hotel Heritage
          Boutique Luxe í Fes þar sem gist er næstu tvær nætur.
        </p>
      </>
    ),
  },
  {
    id: "3",
    date: "15. september 2022",
    title: "Fes.",
    text: (
      <>
        <p>
          Kynnisferð um Fes, hina fornu höfuðborg Marokkó og helstu miðstöð
          menningar landsins. Þarna er líklega elsti háskóli í heimi sem enn
          starfar. „Medínan“, umlukt múrum, er á minjaskrá UNESCO og almennt
          talin best varðveitta miðaldaborg veraldar í íslömskum sið. Hún er
          iðandi af lífi, fjölskrúðugri verslun og handverki sem skemmtilegt er
          að skoða. Eftir hádegisverð á hótelinu gefst tækifæri til að sjá sig
          um í miðborg Fes.. Kvöldverður á Hotel Heritage.
        </p>
      </>
    ),
  },
  {
    id: "4",
    date: "16. september 2022",
    title: "Fes-Marrakech.",
    text: (
      <>
        <p>
          Haldið af stað til Marrakech að loknum morgunverði í lengstu dagleið
          ferðarinnar. Ekið yfir Mið-Atlasfjöll um Ifrane og Azrou og loks
          Benimellal þar sem snæddur verður hádgegisverður á Restaurante Paris.
          Kvöldverður og gisting á Hotel Kenzi Rose Garden (5*****) í miðborg
          Marrakech næstu fjórar nætur.
        </p>
      </>
    ),
  },
  {
    id: "5",
    date: "17. september 2022",
    title: "Fes-Marrakech.",
    text: (
      <>
        <p>
          Haldið af stað til Marrakech að loknum morgunverði í lengstu dagleið
          ferðarinnar. Ekið yfir Mið-Atlasfjöll um Ifrane og Azrou og loks
          Benimellal þar sem snæddur verður hádgegisverður á Restaurante Paris.
          Kvöldverður og gisting á Hotel Kenzi Rose Garden (5*****) í miðborg
          Marrakech næstu fjórar nætur.
        </p>
      </>
    ),
  },
  {
    id: "6",
    date: "17. september 2022",
    title: "Marrakech..",
    text: (
      <>
        <p>
          Að loknum morgunverði er farið i kynnisferð um borgina. Marrakech
          sigraði fyrir nokkrum árum í atkvæðagreiðslu Tripadvisor um besta
          áfangastað í víðri veröld. Segja má að í Marrakech hafi maður annan
          fótinn í nútímanum og hinn í fornöld. Gamli bærinn minnir á Þúsund og
          eina nótt en nýi borgarhlutinn, steinsnar frá, er stórglæsilegur með
          frönskum breiðgötum og formfögrum mannvirkjum í rauðbrúnum jarðarlit í
          stíl við forna múrinn sem umlykur borgina. Skammt fyrir sunnan gnæfa
          svo Atlasfjöllin með snævi þakta tinda sem ramma inn þessa
          tilkomumiklu mynd. Skoðuð eru ýmis sögufræg mannvirki svo sem
          Koutoubia-moskan, grafhýsi Saadi-konungsættarinnar, Bahia-höllin og
          síðast en ekki síst Jema el Fna, eitt magnaðasta torg í víðri veröld.
          Eftir hádegisverð á hótelinu ræður hver sinni dagskrá. Marrakech er
          ævintýraheimur. Það er til dæmis makalaus upplifun að ganga um í
          litríku og glaðværu völundarhúsi medínunnar.
        </p>
      </>
    ),
  },
  {
    id: "7",
    date: "17. september 2022",
    title: "Marrakech..",
    text: (
      <>
        <p>
          Að loknum morgunverði er farið i kynnisferð um borgina. Marrakech
          sigraði fyrir nokkrum árum í atkvæðagreiðslu Tripadvisor um besta
          áfangastað í víðri veröld. Segja má að í Marrakech hafi maður annan
          fótinn í nútímanum og hinn í fornöld. Gamli bærinn minnir á Þúsund og
          eina nótt en nýi borgarhlutinn, steinsnar frá, er stórglæsilegur með
          frönskum breiðgötum og formfögrum mannvirkjum í rauðbrúnum jarðarlit í
          stíl við forna múrinn sem umlykur borgina. Skammt fyrir sunnan gnæfa
          svo Atlasfjöllin með snævi þakta tinda sem ramma inn þessa
          tilkomumiklu mynd. Skoðuð eru ýmis sögufræg mannvirki svo sem
          Koutoubia-moskan, grafhýsi Saadi-konungsættarinnar, Bahia-höllin og
          síðast en ekki síst Jema el Fna, eitt magnaðasta torg í víðri veröld.
          Eftir hádegisverð á hótelinu ræður hver sinni dagskrá. Marrakech er
          ævintýraheimur. Það er til dæmis makalaus upplifun að ganga um í
          litríku og glaðværu völundarhúsi medínunnar.
        </p>
      </>
    ),
  },
  {
    id: "8",
    date: "18. september 2022",
    title: "Marrakech.",
    text: (
      <>
        <p>
          Eftir morgunverð er farið í kynnisferð um borgina og m.a. skoðaðir
          Majorelle-garðarnir undurfögru og íslamskt lærdómssetur (medersa) frá
          14. öld kennt við Ben Youssef, hið stærsta í Marokkó. Hádegisverður
          snæddur á týpísku veitingahúsi, Al Baraka. Eftir hádegið ræðu hver
          sínum ferðum.
        </p>
      </>
    ),
  },
  {
    id: "9",
    date: "19. september 2022",
    title: "Marrakech.",
    text: (
      <>
        <p>Frjáls dagur í Marrakech.</p>
      </>
    ),
  },
  {
    id: "10",
    date: "20. september 2022",
    title: "Marrakech - Casablanca - Rabat.",
    text: (
      <>
        <p>
          {" "}
          Að loknum morgunverði er hótelið kvatt og haldið til Casablanca (244
          km) sem er langstærsta borg Marokkó. Ekið um miðborgina til að berja
          það markverðasta augum, ekki síst moskuna sem kennd er við Hassan II,
          föður núverandi konungs, sem er stærsta íslamska guðshús í heimi að
          undanskildri moskunni í Mecca. Hádegisverður snæddur í Casablanca og
          síðan er haldið áfram til Rabat (92 km), höfuðborgar landsins. Skráð
          sig inn á Hotel Belere (4****), sem stendur í hjarta borgarinnar, og
          kvöldverður snæddur þar.
        </p>
      </>
    ),
  },
  {
    id: "11",
    date: "21. september 2022",
    title: "Rabat - Asilah - Tangier.",
    text: (
      <>
        <p>
          {" "}
          Eftir morgunmat og útskráningu af hótelinu er farið í stutta
          skoðunarferð um Rabat áður en haldið er áfram til Tangier (251 km).
          Hádegisverður snæddur á leiðinni, á fiskveitingahúsinu Casa Garcíaí
          fallegum strandbæ sem nefnist Asilah. Kvöldverður og gisting á Hotel
          Hilton Garden Inn (4****) í Tangier þessa síðustu nótt í Marokkó.
        </p>
      </>
    ),
  },
  {
    id: "12",
    date: "22. september 2022",
    title: "Tangier - Tarifa - Sevilla.",
    text: (
      <>
        <p>
          Að loknum morgunverði á hótelinu er haldið með hraðferjunni kl. 9:00
          (10 á spænskum tíma) aftur yfir Gíbraltarsund til Tarifa og síðan ekið
          sem leið liggur til Sevilla (207 km), höfuðborgar Andalúsíu.
          Hádegisverður snæddur á leiðinni. Skráð sig inn á Hotel NH Plaza de
          Armas (4****) Sevilla, þar sem gist verður næstu tvær nætur.
          Kvöldverður á hótelinu.
        </p>
      </>
    ),
  },
  {
    id: "13",
    date: "24. september 2022",
    title: "Sevilla - Cordoba - Granada.",
    text: (
      <>
        <p>
          Eftir morgunverð er Hótel Melía Sevilla kvatt og ekið áleiðis til
          Cordoba (141 km). Þar var helsta stjórnarsetur og menningarmiðstöð
          máraveldisins Al-Andalus sem drottnaði yfir meginhluta Pýreneaskaga
          frá því snemma á 8. öld og fram á miðja 13. öld. Heimsóknin hefst með
          því að ganga yfir Puente Romano, Rómversku brúna sem lögð var yfir
          Guadalquivir á 1. öld f. Kr. og skoða frægasta mannvirki Cordoba, La
          Mezquita, helsta guðshúsi máranna. Þar til moska Hassans II reis í
          Casablanca var La Mezquita næststærsta moska í heimi. Því næst er
          gengið um nærliggjandi götur gamla borgarhlutans, m.a. gyðingahverfið,
          Barrio Judío, og litið á bænahús gyðinga, La Sinagoga. Snæddur er
          hádegisverður á frægu veitingahúsi, Mesón La Mezquita, áður en haldið
          er áfram til Granada (204 km). Kvöldverður á Hotel Barceló Granada
          Congresos (5*****) í hjarta þessarar dásamlegu borgar.
        </p>
      </>
    ),
  },
  {
    id: "14",
    date: "25. september 2022",
    title: "Granada.",
    text: (
      <>
        <p>
          Eftir morgunverð er skoðað frægasta mannvirki Spánar, Alhambra,
          konungshöll máraríkisins sem hélt velli tvær og hálfa öld eftir að
          kristnir herir sigruðu Cordoba og Sevilla. Einnig er gengið um hina
          fögru skrúðgarða mára á Generalife-hæðinni. Eftir hádegið er farið um
          miðbæinn og m.a. heimsótt Konunglega kapellan ásamt grafhýsi kaþólsku
          konungshjónanna, Ferdínands Aragóníukonungs og Ísabellu drottningar af
          Kastilíu, sem sameinuðu ríki sín, ráku burt márana árið 1492 og gerðu
          Spán að heimsveldi eftir landvinningana í Ameríku. Kvöldverður á
          hótelinu. Eftir það gefst þeim sem vilja kostur á að skoða gamla
          márahverfið, Albayzin, og fara á sígaunaflamenkó.
        </p>
      </>
    ),
  },
  {
    id: "15",
    date: "25. september 2022",
    title: "Granada - Fuente Vaqueros - Marbella.",
    text: (
      <>
        <p>
          Eftir morgunverð er hótelið í Granada kvatt og haldið áleiðis til
          Malaga en höfð viðdvöl í bænum Fuente Vaqueros á hinni grösugu sléttu
          La Vega til að heimsækja fæðingarbæ Federicos Garcia Lorca og skoða
          húsið þar sem þetta ástsælasta skáld Spánar sleit barnsskónum. Síðan
          er ekið til Marbella (180 km) og skráð sig inn á Aparthotel Monarque
          Sultán sem er glæsilegur og aðlaðandi gististaður á „Gullnu mílunni“
          svokölluðu, eftirsóttasta svæðinu í Marbella. Stutt er niður á
          baðströndina og sömuleiðis eru miðbærinn og höfnin í Marbella í
          gangfæri. Þarna verður dvalið næstu 5 nætur. Á hótelinu er auðvelt að
          láta fara vel um sig. Þar eru bæði sundlaugar og „spa“. Innifalið í
          hóteldvölinni er hálft fæði, þ.e. morgunverður og kvöldverður.
        </p>
        <p>
          Þeir sem vilja hafa eitthvað fleira fyrir stafni en að njóta hvíldar
          og makinda í Marbella hafa um margt hnýsilegt að velja, t.d. að
          skreppa til Malaga (59 km) og skoða Picasso-safnið í fæðingarbæ
          meistarans og kannski fá sér tapas á torgum og útiskenkjum í sundum
          miðbæjarins. Eða skjótast til fjallabæjarins Ronda (36 km) sem er einn
          tilkomumesti staður Spánar. Ronda er vagga nautaatsins og þar er elsti
          nautahringur Spánar. Þá eru aðeins 73 km til Gíbraltar frá Marbella.
          Örnólfur þekkir Costa del Sol býsna vel, bjó þar árum saman, og er
          hann fús að leiðbeina fólki og aðstoða við hvaðeina.
        </p>
      </>
    ),
  },
  {
    id: "16",
    date: "25. september 2022",
    title: "Malaga - Keflavik.",
    text: (
      <>
        <p>
          Að loknum morgunverði er hótelið í Marbella kvatt og haldið til
          Malagaflugvallar Flogið með SK4680 til Oslóar. Lent í Noregi kl. 15:15
          og haldið áfram til Keflavíkur kl. 17:20 með SK4785 og Lent þar kl.
          18:10.
        </p>
      </>
    ),
  },
];
export const priceModul = [
  {
    id: "1",
    title: "Verð á mann í tveggja manna herbergi",
    price: "595.000",
  },
  {
    id: "2",
    title: "Aukagjald fyrir einbýli",
    price: "140.000",
  },
  {
    id: "3",
    title: "Staðfestingargjaldi",
    price: "80.000",
  },
];
