import React, { useEffect, useState } from 'react'

export default function Home() {
  return (
    <>
      <section className="hero">
        <div className="auto__container">
          <div className="hero__inner">
            <h2>Greinar</h2>
          </div>
        </div>
      </section>
      <section className="tour">
        <div className="auto__container">
          <div className="tour__inner">
            <div className="tour__inner-header">
              <h3 className="big">Greinar</h3>
            </div>

            <p>
              <a href={process.env.PUBLIC_URL + "greinar/ordones.pdf"} target="_blank">
                Viðtal við nautabanann Antonio Ordones<b>1997</b>
              </a>
            </p>
            <p>
              <a href={process.env.PUBLIC_URL + "greinar/lorca1.pdf"} target="_blank">
                Á aldarafmæli Federico Lorca<b>1998</b>
              </a>
            </p>
            <p>
              <a href={process.env.PUBLIC_URL + "greinar/choukri.pdf"} target="_blank">
                Viðtal við skáldið Mohammed Choukri<b>1997</b>
              </a>
            </p>
            <p>
              <a href={process.env.PUBLIC_URL + "greinar/choukri%20saga.pdf"} target="_blank">
                Ilskór spámannsins, saga eftir Mohammed Choukri, þýð. ÖÁ<b>1997</b>
              </a>
            </p>
            <p>
              <a href={process.env.PUBLIC_URL + "greinar/bowles.pdf"} target="_blank">
                Viðtal við skáldið Paul Bowles<b>1997</b>
              </a>
            </p>
            <p>
              <a href={process.env.PUBLIC_URL + "greinar/wles%20saga.pdf"} target="_blank">
                Arftakinn, saga eftir Paul Bowles, þýð. ÖÁ
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  )
}
