import React from 'react'

export default function Footer() {
  return (
    <footer className="footer">
      <div className="auto__container">
        <div className="footer__inner">
          <div
            className="footer__inner-links"
            style={{ maxWidth: '500px', margin: '0 auto 30px auto' }}
          >
            {/* <a href="trip.html" className="footer__inner-link">
              Ferðir
            </a> */}
            {/* <span></span>
            <a href="#" className="footer__inner-link">
              Fyrir Hópa
            </a> */}
            {/* <span></span> */}
            <a href="/utvarp" className="footer__inner-link">
              Útvarpsþættir
            </a>
            <span></span>
            <a href="/greinar" className="footer__inner-link">
              Greinar
            </a>
            {/* <span></span>
            <a href="#" className="footer__inner-link">
              Um okkur
            </a> */}
          </div>
          <p className="uniq">Óðinn fjölmiðlun ehf. - Allur réttur áskilinn</p>
        </div>
      </div>
    </footer>
  )
}
