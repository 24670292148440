import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Footer from './Base/Footer'
import Header from './Base/Header.jsx'
import Home from './Pages/Home/Home'
import Trip from './Pages/Trip/Trip'

import Utvarp from './Pages/Utvarp/Utvarp'
import Greinar from './Pages/Greinar/Greinar'
import Trips from './Pages/Trips/Trips'

export default function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/ferd/:slug" element={<Trip />} />
        <Route path="/utvarp" element={<Utvarp />} />
        <Route path="/greinar" element={<Greinar />} />
        <Route path="/trips" element={<Trips />} />
      </Routes>
      <Footer />
    </>
  )
}
