import React, { useEffect, useState } from 'react'

export default function Home() {
  console.log('HELLO')
  return (
    <>
      <section className="hero">
        <div className="auto__container">
          <div className="hero__inner">
            <h2>Útvarpsþættir</h2>
          </div>
        </div>
      </section>
      <section className="tour">
        <div className="auto__container">
          <div className="tour__inner">
            <div className="tour__inner-header">
              <h3 className="big">Mannlíf við miðbaug</h3>
              <h5 className="gray">10 þættir, 2014</h5>
            </div>
            <>
              <p>
                1. þáttur: Singapúr.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/mannlifvidmidbaug/Mannlif_vid_midbaug_1_140614_Singapur.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                2. þáttur: Malasía. Malakka.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/mannlifvidmidbaug/Mannlif_vid_midbaug_2_140621_Malasia.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                3. þáttur: Malasía. Kuala Lumpur.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/mannlifvidmidbaug/Mannlif_vid_midbaug_3_140628_Kuala_Lumpur.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                4. þáttur: Singapúr.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/mannlifvidmidbaug/Mannlif_vid_midbaug_4_140705_Singapur.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                5. þáttur: Balí. Ubud. Viðtal við Tjokorda konung.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/mannlifvidmidbaug/Mannlif_vid_midbaug_5_140712_Indonesia.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                6. þáttur: Balí. Ferðir um eyjuna. Tjokorda frh.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/mannlifvidmidbaug/Mannlif_vid_midbaug_6_140719_Bali.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                7. þáttur: Balí. Semninyak. Viðtöl.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/mannlifvidmidbaug/Mannlif_vid_midbaug_7_140726_Bali.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                8. þáttur: Java. Jakarta. Viðtal við Búa Erlendsson.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/mannlifvidmidbaug/Mannlif_vid_midbaug_8_140802_Java.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                9. þáttur: Björgúlfur Ólafsson. Viðtal við Gunavan konsúl.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/mannlifvidmidbaug/Mannlif_vid_midbaug_9_140809_Jakarta.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                10. þáttur: Singapúr. Viðtöl við singapúrska rithöfunda.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/mannlifvidmidbaug/Mannlif_vid_midbaug_10_140816_Singapur.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
            </>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="auto__container">
          <div className="tour__inner">
            <div className="tour__inner-header">
              <h3 className="big">Andi Andalúsíu</h3>
              <h5 className="gray">6 þættir, 2006</h5>
            </div>
            <>
              <p>
                1. þáttur: Inngangur. Viðtal við borgarstjóra Palma del Rio.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/andi_andalusiu/Andi_Andalúsíu_1._þáttur.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                2. þáttur: Córdoba. Eðli Andalúsíumannsins. J.M. Gómez viðtal. <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/andi_andalusiu/Andi_Andalúsíu_2._þáttur.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                3. þáttur: Córdoba. Þorpsandi. Joan Font leiksýning. <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/andi_andalusiu/Andi_Andalúsíu_3._þáttur.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                4. þáttur: Hugarfar Andalúsímanna. Seneca. Ýmsar pælingar.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/andi_andalusiu/Andi_Andalúsíu_4._þáttur.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                5. þáttur: Córdoba. Maimonides. Averroes.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/andi_andalusiu/Andi_Andalúsíu_5._þáttur.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                6. þáttur: Gítar. Manolo Sanlúcar. Duende. Lorca. Picasso.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/andi_andalusiu/Andi_Andalúsíu_6._þáttur.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
            </>
          </div>
        </div>{' '}
        <br />
        <br />
        <br />
        <br />
        <div className="auto__container">
          <div className="tour__inner">
            <div className="tour__inner-header">
              <h3 className="big">Sígaunalíf</h3>
              <h5 className="gray">6 þættir, 2002</h5>
            </div>
            <>
              <p>
                1. þáttur: Uppruni sígauna og saga. Flamenco. Sacromonte.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/sigaunalif/sl1.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                2. þáttur: Slæmt orðspor sígauna í Evrópu og á Spáni. Hrekkir þeirra.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/sigaunalif/sl2.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                3. þáttur: Sígaunar á Spáni. Vínhátíðin í Jerez. <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/sigaunalif/sl3.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                4. þáttur: Saga sígauna. Hátíðin. Lorca.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/sigaunalif/sl4.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                5. þáttur: 19. öld. Borrow. Starkie. Siðir sígauna. Hátíðin.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/sigaunalif/sl5.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                6. þáttur: Ráðhústorgið í Jerez. Viðtal við Manuel Morao.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/sigaunalif/sl6.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
            </>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="auto__container">
          <div className="tour__inner">
            <div className="tour__inner-header">
              <h3 className="big">Ykkar maður í Havana</h3>
              <h5 className="gray">4 þættir, 2000</h5>
            </div>
            <>
              <p>
                1. þáttur.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/havana/Ykkar_maður_í_Havana_1._þáttur.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                2. þáttur.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/havana/Ykkar_maður_í_Havana_2._þáttur.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                3. þáttur.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/havana/Ykkar_maður_í_Havana_3._þáttur.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                4. þáttur.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={
                      'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/havana/Ykkar_maður_í_Havana_4._þáttur.mp3'
                    }
                    type="audio/mpeg"
                  />
                </audio>
              </p>
            </>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="auto__container">
          <div className="tour__inner">
            <div className="tour__inner-header">
              <h3 className="big">Ykkar maður á Kúbu</h3>
              <h5 className="gray">4 þættir, 2001</h5>
            </div>
            <>
              <p>
                1. þáttur: Havana. Bollaleggingar um Kúbu.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/a_kubu/kuba1.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                2. þáttur: Nútímasaga. Tóbak. Havanavindlar. Viðtal við ljóðskáld.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/a_kubu/kuba2.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                3. þáttur: Santiago de Cuba.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/a_kubu/kuba3.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                4. þáttur: Heimsókn með gjafir til Batabanó.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/a_kubu/kuba4.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
            </>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="auto__container">
          <div className="tour__inner">
            <div className="tour__inner-header">
              <h3 className="big">Andalúsía, syðsta byggð álfunnar</h3>
              <h5 className="gray">8 þættir, 1998</h5>
            </div>
            <>
              <p>
                1. þáttur: Upphaf kynna Örnólfs af Spáni. Bækur um Spán. Tilvitnanir.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/andalusia_sydsta/andal1.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                2. þáttur: Torremolinos. Malagahérað. Antequera. Picasso.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/andalusia_sydsta/andal2.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                3. þáttur: Torremolinos. Fiskiþorpið Carihuela. Lorca. Alicia de Larrocha.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/andalusia_sydsta/andal3.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                4. þáttur: Granada. Lorca. Sígaunar í Sacromonte.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/andalusia_sydsta/andal4.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                5. þáttur: Stemmningar í Torremolinos. Seneca. Franco. Juan Carlos.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/andalusia_sydsta/andal5.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                6. þáttur: Carmen, ópera S. Távora. í Ronda. Antonio Ordoñes.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/andalusia_sydsta/andal6.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                7. þáttur: Nýbúar á Costa del Sol. Jörgen Poulsen. Hátíð í Coín.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/andalusia_sydsta/andal7.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                8. þáttur: Marbella. Trevor Morais. Andalúsíska. Golf. Saga. Lorca.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/andalusia_sydsta/andal8.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
            </>
          </div>
        </div>{' '}
        <br />
        <br />
        <br />
        <br />
        <div className="auto__container">
          <div className="tour__inner">
            <div className="tour__inner-header">
              <h3 className="big">Í veröld Márans</h3>
              <h5 className="gray">5 þættir, 1997</h5>
            </div>
            <>
              <p>
                1. þáttur: Essaouira.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/verold_marans/vm1.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                2. þáttur: Rabat. Saga. Paul Bowles.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/verold_marans/vm2.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                3. þáttur: Marrakech.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/verold_marans/vm3.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                4. þáttur: Ferð til Agadir. Agadir.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/verold_marans/vm4.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
              <p>
                5. þáttur: Casablanca.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source
                    src={'https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/verold_marans/vm5.mp3'}
                    type="audio/mpeg"
                  />
                </audio>
              </p>
            </>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="auto__container">
          <div className="tour__inner">
            <div className="tour__inner-header">
              <h3 className="big">Hjá Márum</h3>
              <h5 className="gray">4 þættir, 1996</h5>
            </div>
            <>
              <p>
                1. þáttur: Almennt um Marokkó. Paul Bowles. Marrakech. Essaouira.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source src="https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/hja_marum/hm1.mp3" type="audio/mpeg" />
                </audio>
              </p>
              <p>
                2. þáttur: Essaouira.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source src="https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/hja_marum/hm2.mp3" type="audio/mpeg" />
                </audio>
              </p>
              <p>
                3. þáttur: Agadir, Tarroudant, Ourzazate.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source src="https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/hja_marum/hm3.mp3" type="audio/mpeg" />
                </audio>
              </p>
              <p>
                4. þáttur: Marrakech.
                <br />
                <br />
                <audio controls preload="metadata">
                  <source src="https://res.cloudinary.com/dj0wwxv7z/video/upload/v1721645678/ornolfur/hja_marum/hm4.mp3" type="audio/mpeg" />
                </audio>
              </p>
            </>
          </div>
        </div>
      </section>
    </>
  )
}
