import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header className="header">
      <div className="auto__container">
        
        <div style={{display: "flex", justifyContent: "flex-start"}}>
          <a href="https://www.facebook.com/baliferdirornolfs/" target="_blank" className="header__inner-social">
            <img src={process.env.PUBLIC_URL + "/images/facebook.svg"} alt="" style={{height: "20px", height: "20px"}}/>
          </a>
          
          {/* <a href="https://instagram.com/ornolfur" target="_blank" className="header__inner-social">
            <img src={process.env.PUBLIC_URL + "/images/instagram.svg"} alt="" style={{height: "20px", height: "20px", marginLeft: "10px"}}/>
          </a> */}
          <p style={{color: "white", margin: "-5px 10px"}}>ornolfur@islandia.is</p>
        </div>

        <div className="header__inner">
          <Link to="/" className="header__inner-logo">
            <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="" style={{height: "60px", maxWidth: "80%"}}/>
            
          </Link>
        </div>
      </div>
    </header>
  );
}
